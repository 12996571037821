exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-[slug]-tsx": () => import("./../../../src/pages/[slug].tsx" /* webpackChunkName: "component---src-pages-[slug]-tsx" */),
  "component---src-pages-biometricchecker-tsx": () => import("./../../../src/pages/biometricchecker.tsx" /* webpackChunkName: "component---src-pages-biometricchecker-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-distancechecker-tsx": () => import("./../../../src/pages/distancechecker.tsx" /* webpackChunkName: "component---src-pages-distancechecker-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-institutechecker-tsx": () => import("./../../../src/pages/institutechecker.tsx" /* webpackChunkName: "component---src-pages-institutechecker-tsx" */),
  "component---src-pages-notification-tsx": () => import("./../../../src/pages/notification.tsx" /* webpackChunkName: "component---src-pages-notification-tsx" */),
  "component---src-pages-notifications-tsx": () => import("./../../../src/pages/notifications.tsx" /* webpackChunkName: "component---src-pages-notifications-tsx" */),
  "component---src-pages-sanctionsnechecker-tsx": () => import("./../../../src/pages/sanctionsnechecker.tsx" /* webpackChunkName: "component---src-pages-sanctionsnechecker-tsx" */),
  "component---src-pages-trainingchecker-tsx": () => import("./../../../src/pages/trainingchecker.tsx" /* webpackChunkName: "component---src-pages-trainingchecker-tsx" */)
}

